.outerContainer {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    height: 122vh;
    background-color: #FFFFFF;
    padding-top: 50px;
    width: 2600px;
    position: relative;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 8px;
    height: 60%;
    width: 35%;
}

.profile_details {
    display: flex;
    justify-content: left;
    flex-direction: column;
    background-color: white;
    width: 350px;
    height: 70vh;
    margin-right: 10px;
    margin-left: 10px;
}

.inner_input {
    background-color: #E8E8E8;
}

.upper_half {
    height: 45%;
    display: flex;
    background-color: #E8E8E8;
    border-radius: 10px;
    flex-direction: column;
}


.lower_half {
    height: 60%;
    display: flex;
    background-color: #E8E8E8;
    border-radius: 10px;
    flex-direction: column;
    margin-top: 5px;
}

.more_details {
    color: white;
    height: 30px;
    background-color: gray;
    padding: 5px;
    padding-left: 20px;
    border-radius: 10px;
}

.coins {
    color: black;
    height: 30px;
    background-color: rgb(198, 240, 170);
    padding: 5px;
    padding-left: 20px;
    border-radius: 10px;
    margin-top: 2px;
    width: 50%;
    border: 1px solid rgb(161, 155, 155);
    margin-right: 3px;
}

.first_name {
    color: black;
    height: 30px;
    background-color: rgb(255, 250, 201);
    padding: 5px;
    padding-left: 20px;
    border-radius: 10px;
    margin-top: 2px;
    width: 99%;
    border: 1px solid rgb(161, 155, 155);
}

.more_details_input {
    background-color: #E8E8E8;
    height: 80%;
    margin-top: 20px;
    border: none;
    display: flex;
    /* caret-color: transparent; */
}

.dflex {
    display: flex !important;
}

.profile_detail_input {
    border: none;
    background-color: rgb(255, 250, 201);
    margin-left: 4px;
}

.react-datepicker-wrapper{
    position: absolute !important;
    margin-left: 40px;
    margin-right: 20px;
}
.top_bar {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1630px;
    /* Replace with your desired max width */
    height: 26px;
    background-color: gray;
    z-index: 1;
    border-radius: 0px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
.top_bar_secondary{
    border-bottom-left-radius: 45px;
    display: flex;
    justify-content: start;
    border-bottom-right-radius: 45px;
    width: 100%;
    max-width: 1580px;
    flex-direction: column;
    position: relative;
    padding-top: 10px;
}
.background-wrapper{
    display: flex;
    justify-content: start;
    width: 100%;
    max-width: 1580px;
    flex-direction: column;
}
.client-detai-text{
    font-size: 17px;
    font-weight: bold;
}

.top_bar_contents{
    margin-left: 90px;
}
.client-detail-section{
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 5px;
}
.top_bar_secondary_content{
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1580px;
    border-bottom-left-radius: 45px;
    border-bottom-right-radius: 45px;
    padding-top: 20px;
    position: absolute;
    margin-top: 60px;
}

.client_details_history{
    background-color: #E8E8E8;
    display: flex;
    position: relative;
    width: 100%;
    max-width: 1580px;
    margin-top: 450px;
    height: 700px;
    flex-direction: column;
}

.green-tick {
    display: inline-block;
    color: green;
    font-size: 14px;
    margin-left: 5px;
}

.profile_detail_input[data-tick='true']::after {
    content: '✓';
    display: inline-block;
    color: green;
    font-size: 18px;
    margin-left: 5px;
}

.top_bar_content {
    padding: 5px;
    
}
.more_details_input{
    padding-left: 10px;
    width: 80%;
}




@media (min-width: 320px) and (max-width: 480px) {
    .outerContainer {
        height: 100%;
    }

    .container {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 480px) and (max-width: 1200px) {
    .container {
        width: 60%;
    }
}