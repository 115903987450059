.form {
	display: flex;
	border-top: none;
	padding-top: 5px;
	flex-direction: column;
	align-items: stretch;
}

.input {
	border: none;
	border-radius: 10px;
	padding: 5%;
	width: 100%;
	font-size: 0.8em;
	background-color: #E8E8E8;
    margin-bottom: 10px;
}

.Inner_input{
	margin-left: 10px;
	border: none;
	border-radius: 20px;
	padding: 0.6%;
	width: 99%;
	background-color: cornsilk;
	display: flex;
	/* margin-right: 20px; */
}
.inner_input_div{
	height: 130px;
	border-radius: 20px;
}

input:focus, textarea:focus, select:focus {
	outline: none;
}

.sendButton {
	color: #fff !important;
	text-transform: uppercase;
	text-decoration: none;
	background: #2979FF;
	padding: 20px;
	display: inline-block;
	border: none;
	border-radius: 20px;
	width: 20%;
}

.sendButtonDivTest{
	background-color: white	;
	padding: 10px;
	border-radius: 10px;
	margin-top: 7px;
	margin-bottom: 7px;
}

.button2{
	margin-left: 10px;
}
